import axios from "axios";
import { toast } from "react-toastify";

function debounce(callback, delay = 1000){
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            callback(...args);
        }, delay);
    }
}

async function uploadImageUtility(file: File, fallbackSrc?: string){
    let srcValue = fallbackSrc ? fallbackSrc : "";

    try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/formupload`,
            formData,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.status === 200) {
            srcValue = response.data?.item?.src;
        }
    } catch (error) {
        console.error('Error uploading or fetching image:', error);
        toast.error('Upload failed!');
    }
    return srcValue;
};

function getLabelByStemNumeration(more_options : any, index: number) {
    let label = !isNaN(Number(index)) ? (index + 1).toString() : "";
    const stemNumeration = more_options && more_options?.layout?.validation_stem_numeration ?
         more_options?.layout?.validation_stem_numeration : "";
    if (stemNumeration) {
        switch (stemNumeration) {
            case 'lowercase':
                label = String.fromCharCode(97 + index);
                break;
            case 'uppercase':
                label = String.fromCharCode(97 + index).toUpperCase();
                break;
            default:
                break;
        }
    }
    return label;
}

function getResponseFontSize(more_options: any) {
    const fontScale = more_options && more_options?.layout?.template_font_scale ? 
    more_options?.layout?.template_font_scale : 
    "boosted";

    const fontSize = more_options && more_options?.layout?.fontSize ? 
    more_options.layout.fontSize : 
    'medium';

    let fontsMap : any = {
        small: '13px',
        medium:	'16px',
        large: '18px',
        "x-large":	'24px',
        "xx-large":	'32px'
    }

    // normal or boosted
    const modifiedFontSize = fontScale != "normal" 
    ? `calc(${fontsMap[fontSize]} * 1.5)`
    : fontSize;

    return modifiedFontSize;
}

function getRandomColor() {
    const r = Math.floor(Math.random() * 256); // Red value
    const g = Math.floor(Math.random() * 256); // Green value
    const b = Math.floor(Math.random() * 256); // Blue value
    return `rgb(${r}, ${g}, ${b})`;
  }
  
// onChange = {debounce(e => {conosole.log(e.target.value)})}

export {
    debounce,
    uploadImageUtility,
    getLabelByStemNumeration,
    getResponseFontSize,
    getRandomColor,
}