import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import DraggableElement from "../partials/DraggableElement/DraggableElement";
import Button from "components/Button";
import DroppableArea from "../partials/DroppableArea/DroppableArea";
import { TOptionItem } from "types";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
 

type ClassificationBlocPrevProps = {
  columnTitles: TOptionItem[];
  rowsTitles: TOptionItem[];
  possibRes: TOptionItem[];
  correctAnswers?: string[][];
  handlePointsChange?: (e: any) => void;
  editMode?: boolean;
  showAnswer?: boolean;
  score?: number;
  handleDataRest?: any;
  currentQuestion?: any;
};

export const ClassificationBlocPrev: React.FC<ClassificationBlocPrevProps> = ({
  columnTitles,
  rowsTitles,
  possibRes,
  correctAnswers,
  handlePointsChange,
  editMode,
  showAnswer,
  score,
  handleDataRest,
  currentQuestion,
}) => {
  const { t } = useTranslation();
  const colRef = useRef<Array<HTMLSpanElement | null>>([]);
  const rowRef = useRef<Array<HTMLSpanElement | null>>([]);
  const subQuestion = useSelector(
    (state: RootState) =>
      state.question.subQuestions);
    console.log(currentQuestion, " SUb Question");
  /*const row_min_height = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.more_options?.ui_style
        ?.row_min_height
  );*/
  const row_min_height = currentQuestion?.more_options?.ui_style
  ?.row_min_height;
  console.log(row_min_height, " Row Min Height");
  
  const row_titles_width =currentQuestion?.more_options?.ui_style
  ?.row_titles_width;
  
  const isDuplicateResponse = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.more_options
        ?.duplicate_responses
  );
  const correctAns = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.correct_answer
        ?.possible_responses
  );
  const [droppedItems, setDroppedItems] = useState<{ [key: number]: string[] }>(
    {}
  );
  const max_response_per_cell = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.more_options?.ui_style
        ?.max_response_per_cell
  );
  const [availableItems, setAvailableItems] = useState<string[]>(possibRes?.map((item) => item.label));
  const [hasAnswered, setHasAnswered] = useState<boolean>(false);
  const [showCheckAnswer, setShowCheckAnswer] = useState<boolean>(false); // State to track show/hide answer classification

  // Track if at least one item has been answered
useEffect(() => {
  // Check if any droppable area has at least one dropped item
  const anyItemsDropped = Object.values(droppedItems).some((items) => items.length > 0);
  setHasAnswered(anyItemsDropped);
}, [droppedItems]);


useEffect(() => {
  colRef.current = colRef.current.slice(0, columnTitles?.length);
}, [columnTitles]);

useEffect(() => {
  rowRef.current = rowRef.current.slice(0, rowsTitles?.length);
}, [rowsTitles]);

const more_options = useSelector(
  (state: RootState) =>
    (state.question.subQuestions?.["class"] as any)?.more_options
);

const getLabel = (index: number) => {
  let label = "";
  if (more_options?.ui_style?.validation_stem_numeration) {
    switch (more_options?.ui_style?.validation_stem_numeration) {
      case "numerical":
        label = (index + 1).toString();
        break;
      case "lower-alpha":
        label = String.fromCharCode(97 + index);
        break;
      case "upper-alpha":
        label = String.fromCharCode(65 + index);
        break;
      default:
        break;
    }
  }
  return label;
};

useEffect(() => {
  columnTitles?.forEach((item, i) => {
    if (colRef.current[i]) {
      colRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
    }
  });
}, [columnTitles]);

useEffect(() => {
  rowsTitles?.forEach((item, i) => {
    if (rowRef.current[i]) {
      rowRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
    }
  });
}, [rowsTitles]);

  const handleDrop = (id: number, label: string) => {
    if (id === -1) {
      setAvailableItems((prev) => {
        if (!prev.includes(label)) {
          return [...prev, label];
        }
        return prev;
      });

      setDroppedItems((prev) => {
        const updatedItems = { ...prev };
        Object.keys(updatedItems).forEach((key) => {
          updatedItems[key] = updatedItems[key].filter((item) => item !== label);
        });
        return updatedItems;
      });
    } else {
      setDroppedItems((prev) => {
        const updatedItems = { ...prev };

        if (!updatedItems[id]) {
          updatedItems[id] = [];
        }

        if (max_response_per_cell !== 0) {
          if (updatedItems[id].length >= max_response_per_cell) {
            const removedItem = updatedItems[id].shift();
            if (!isDuplicateResponse && removedItem) {
              setAvailableItems((prev) => [...prev, removedItem]);
            }
          }
        }

        if (isDuplicateResponse) {
          updatedItems[id] = [...updatedItems[id], label];
        } else {
          if (!updatedItems[id].includes(label)) {
            updatedItems[id].push(label);
          }
        }
        return updatedItems;
      });
      if (!isDuplicateResponse) {
        setAvailableItems((prev) => prev.filter((item) => item !== label));
      }
    }
  };
  const getHeightClass = () => {
    console.log(row_min_height, " Height");
    if (!row_min_height) return '';
    return `h-[${row_min_height}px]`;
  };

  const handleCheckAnswer = ()=>{
    console.log(correctAnswers, showAnswer, " Answers");
  }



  console.log("correctAnswers", correctAnswers);
  console.log(row_min_height, " Min Height");
  const handleCheckAnswerButton = (e) => {
    e.preventDefault();
    setShowCheckAnswer(!showCheckAnswer);
  };

  const draggableArea = () => {
    return (
      <div
        className="overflow-x-scroll pb-4"
      // style={row_min_height ? { minHeight: `${row_min_height}px` } : {}}
      >      
      <table>
          <tbody>
            <tr>
              <th className="p-2 border-solid border border-grey-500 my-4 rounded-md min-h-[48px]"  style={{
                    width: row_titles_width ? `${row_titles_width}px` : '48px'
                  }}>&nbsp;</th>
              {columnTitles?.map((item, key) => (
                <th key={key} className="p-2 border-solid border border-grey-500 my-4 rounded-md min-w-[20vw] min-h-[48px]">
                  <span ref={(el) => (colRef.current[key] = el)} />
                </th>
              ))}
            </tr>

            {rowsTitles?.map((item, rowIndex) => (
              <tr key={rowIndex}>
                <td className="p-2 border-solid border border-grey-500 my-4 rounded-md min-h-[48px]"
                  style={{
                    width: row_titles_width ? `${row_titles_width}px` : '48px'
                  }}>
                  <span ref={(el) => (rowRef.current[rowIndex] = el)} />
                </td>
                {columnTitles?.map((_, colIndex) => (
                  <th key={colIndex} className="p-2 border-solid border border-grey-500 my-4 rounded-md ">
                     <DroppableArea
                      id={rowIndex * columnTitles.length + colIndex}
                      onDrop={handleDrop}
                      droppedItems={
                        droppedItems[
                        rowIndex * columnTitles.length + colIndex
                        ] || []
                      }
                      // className={getHeightClass()}
                      height={row_min_height}
                    />
                    {(showAnswer || showCheckAnswer) && 
                      correctAnswers &&
                      correctAnswers[rowIndex * columnTitles.length + colIndex]?.map((answer, key) => {
                        const isCorrect =
                          droppedItems[rowIndex * columnTitles.length + colIndex]?.[key] === answer;
                        return (
                          <div
                            key={key}
                            // className={`border-2 p-1 my-1 ${
                            //   droppedItems[
                            //     rowIndex * columnTitles.length + colIndex
                            //   ]?.includes(answer)
                            //     ? "border-green-500"
                            //     : "border-red-500"
                            // }`}
                            className={`border-2 p-1 my-1 ${isCorrect ? "border-green-500" : "border-red-500"
                              }`}
                          >
                             <span>
                              {getLabel(
                                rowIndex * columnTitles.length + colIndex
                              )}
                              .{" "}
                            </span>
                            <span
                              dangerouslySetInnerHTML={{ __html: answer }}
                            />
                          </div>
                        );
                      })}
                  </th>
                ))}
              </tr>
            ))}
           
          </tbody>
        </table>
      </div>
    );
  };
  const instant_feedback = more_options?.ui_style?.instant_feedback;

  const possibility_list_position = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.more_options?.ui_style
        ?.possibility_list_position
  );

  const getDroppableArea = () => (
    <DroppableArea
      id={-1}
      onDrop={handleDrop}
      droppedItems={availableItems}
      className="flex items-center gap-1 flex-wrap bg-red-500"
    />
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {handleDataRest && (
        <Button variant="contained" title={t("reset")} onClick={handleDataRest} />
      )}

      <div className="mt-4">
        {possibility_list_position === "top" && (
          <div className="mb-4">{getDroppableArea()}</div>
        )}
        {possibility_list_position !== "left" &&
          possibility_list_position !== "right" &&
          draggableArea()}
        {possibility_list_position === "bottom" && (
          <div className="mt-4">{getDroppableArea()}</div>
        )}

        {possibility_list_position === "left" && (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "1rem" }}>{getDroppableArea()}</div>
            {draggableArea()}
          </div>
        )}

        {possibility_list_position === "right" && (
          <div style={{ display: "flex" }}>
            {draggableArea()}
            <div style={{ marginLeft: "1rem" }}>{getDroppableArea()}</div>
          </div>
        )}
{editMode && (
          <div
            className="flex items-center gap-10 p-2"
            style={{
              border: "1px solid #eee",
              borderRadius: "6px",
              width: "300px",
            }}
          >
            <span>{t("points")}</span>
            {score ? (
              <input
                className="outline-none"
                type="number"
                value={score}
                readOnly
              />
            ) : (
              <input
                className="outline-none"
                placeholder="100"
                type="number"
                onChange={handlePointsChange}
              />
            )}
          </div>
        )}
        {/* Check Answer Button */}
        {instant_feedback && (

        <div className="mt-6 flex justify-end">
          <button
            disabled={!hasAnswered}
            onClick={handleCheckAnswerButton}
            className={`text-right font-semibold py-2 px-4 border rounded transition duration-300 ${
              !hasAnswered
                ? "bg-[#aaaaaa] text-[#717171] cursor-not-allowed"
                : "bg-transparent hover:bg-accent text-gray-700 hover:text-white border hover:border-transparent"
            }`}
          >
            Check Answer
          </button>
        </div>
        )}

      </div>
    </DndProvider>
  );
};

ClassificationBlocPrev.defaultProps = {
  editMode: false,
};
