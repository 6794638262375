import { createSlice } from '@reduxjs/toolkit';

interface IEditMode {
  editMode: boolean;
  questionType: string;
  subQuestionId: string;
  saveDraftQuestion: string;
  firstOpen: boolean;
  openConfirmationModal: boolean;
}

const initialState: IEditMode = {
  editMode: false,
  questionType: null,
  subQuestionId: null,
  saveDraftQuestion: null,
  firstOpen: false,
  openConfirmationModal: false,
};

const editModeSlice = createSlice({
  name: 'editMode',
  initialState,
  reducers: {
    openEditMode: (state) => {
      state.editMode = true;
    },
    closeEditMode: (state) => {
      state.editMode = false;
    },
    setTypeQuestionOpenInEditMode: (state, action) => {
      if (action.payload) {
        const { type, id } = action.payload;
        if (type && id) {
          state.questionType = type;
          state.subQuestionId = id;
        }
      }
    },
    setTypeQuestionOpenInCreateMode: (state, action) => {
      const { saveDraftQuestion, firstOpen } = action.payload;
      // state.openConfirmationModal = state.questionType !== saveDraftQuestion;
      state.questionType = state.saveDraftQuestion;

      state.firstOpen = firstOpen;
      state.saveDraftQuestion = saveDraftQuestion;
    },
    closeConfirmationModal: (state) => {
      state.openConfirmationModal = false;
    },
    openConfirmationModal: (state) => {
      state.openConfirmationModal = true;
    },
    resetEditModeData: () => {
      return initialState;
    },
  },
});

export const {
  openEditMode,
  closeEditMode,
  setTypeQuestionOpenInEditMode,
  setTypeQuestionOpenInCreateMode,
  closeConfirmationModal,
  openConfirmationModal,
  resetEditModeData,
} = editModeSlice.actions;
export default editModeSlice.reducer;
