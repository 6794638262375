const ChartQuestionTypes = Object.freeze({
    BARCHART: 'bc',
    LINECHART: 'lc',
    HISTOGRAM: 'his',
    DOTPLOT: 'dotplot',
    LINEPLOT: 'lineplot',
});

const DrawingTypes = Object.freeze({
    DRAWING: 'draw',
    SHADING: 'shad',
    TOKEN_HIGHLIGHT: 'tohigh',
    HOT_SPOT: 'hotsp',
})

export {
    ChartQuestionTypes,
    DrawingTypes,
}