import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setKeyValue } from '../../../redux/slices/QuestionSlice';
import { setEditKeyValue } from '../../../redux/slices/EditSubQuestionSlice';
import { FaCheck, FaTimes } from "react-icons/fa";

interface GameBoardProps {
    row?: number;
    column?: number;
    canvasContainerStyle?: {
        left?: {
            border_width?: string;
            border_style?: string;
            border_color?: string;
        };
        top?: {
            border_width?: string;
            border_style?: string;
            border_color?: string;
        };
        right?: {
            border_width?: string;
            border_style?: string;
            border_color?: string;
        };
        bottom?: {
            border_width?: string;
            border_style?: string;
            border_color?: string;
        };
    };
}

const GameBoard = styled.div<GameBoardProps>`
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(${(props) => props.column}, 1fr);
  justify-items: stretch;
  margin: 0 auto;
  background-color: #35363a;
  max-width: ${(props) => props.column ? `calc(${props.column} * 104px)` : '0px'};
  
  border-left: ${(props) =>
    props.canvasContainerStyle?.left
        ? `${props.canvasContainerStyle.left.border_width}px ${props.canvasContainerStyle.left.border_style} ${props.canvasContainerStyle.left.border_color}`
        : 'none'};
  border-top: ${(props) =>
    props.canvasContainerStyle?.top
        ? `${props.canvasContainerStyle.top.border_width}px ${props.canvasContainerStyle.top.border_style} ${props.canvasContainerStyle.top.border_color}`
        : 'none'};
  border-right: ${(props) =>
    props.canvasContainerStyle?.right
        ? `${props.canvasContainerStyle.right.border_width}px ${props.canvasContainerStyle.right.border_style} ${props.canvasContainerStyle.right.border_color}`
        : 'none'};
  border-bottom: ${(props) =>
    props.canvasContainerStyle?.bottom
        ? `${props.canvasContainerStyle.bottom.border_width}px ${props.canvasContainerStyle.bottom.border_style} ${props.canvasContainerStyle.bottom.border_color}`
        : 'none'};
`;

interface GameItemProps {
    flipped: boolean;
    correct: boolean;
    incorrect: boolean;
    matched: boolean;
    width: number;
    height: number;
    borderColor?: string;
    image?: string;
    isSelected?: boolean;
    isShaded?: boolean;
    isHidden?: boolean;
    lockShadedCells?: boolean;
    mode?: string;
    action?: string;
    borderType?: 'Full' | 'Outer' | 'None';
}

const GameItem = styled.div<GameItemProps>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  background-color: ${(props) =>
    props.isSelected
        ? '#ffffff' // White background for selected items
        : props.isShaded
            ? '#cccccc' // Light gray for shaded items
            : props.isHidden
                ? 'white' // Red background for hidden items
                : '#007bff'}; // Default background color
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: ${(props) => ((props.isShaded || props.isHidden) && props.lockShadedCells ? 'not-allowed' : 'pointer')};
  overflow: hidden;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-sizing: border-box;

  ${(props) => {
    if (props?.borderType) {
        // Apply border based on the user's selected borderType in moreOptions
        switch (props?.borderType) {
            case 'Full':
                return `border: 2px solid ${props.borderColor || 'black'};`;
            case 'Outer':
                return `
          `;
            case 'None':
                return `border: none;`;
            default:
                return '';
        }
    } else {
        // Default border styling when borderType is not set
        return `
        border: ${props.isHidden
            ? 'none' // No border when the item is hidden
            : props.borderColor
                ? `2px solid ${props.borderColor}` // Use border color if provided
                : '2px solid black'}; // Default black border if no borderColor provided
      `;
    }
}}

  ${(props) =>
    props.flipped &&
    css`
      transform: rotateY(180deg);
    `}
`;

interface MemoryGameProps {
    currentQuestion?: object;
    mode?: string;
    action?: string;
    cellWidth?: number;
    cellHeight?: number;
    borderColor?: string;
    image?: string;
    showAnswer?: boolean; // Add showAnswer prop to indicate if correct answer should be displayed
    correctAnswer?: [number, number][]; // Correct answer positions
    shadedCells?: [number, number][]; // Shaded cells positions
    lockShadedCells?: boolean; // Lock state for shaded cells
    isAltAnswer?: boolean; // New prop to indicate if the current interaction is for altAnswer
    altIndex?: number; // New prop to indicate if the current interaction is for altAnswer
    method?: string; // New prop to indicate if the current interaction is for altAnswer
}

const MemoryGame: React.FC<MemoryGameProps> = ({
    currentQuestion,
    mode = 'create',
    cellWidth = 100,
    cellHeight = 100,
    borderColor,
    image,
    showAnswer = false,
    correctAnswer = [],
    shadedCells = [],
    lockShadedCells = true,
    action,
    isAltAnswer = false, // Default is false for correct answer
    altIndex , // Default is false for correct answer
    method
    }) => {
    const dispatch = useDispatch();
    const shading = useSelector(
        (state: RootState) => state.question.subQuestions?.shading
    );

    const question = currentQuestion ? currentQuestion : shading;

    const [items, setItems] = useState<string[]>([]);
    const [flippedItems, setFlippedItems] = useState<[number, number][]>([]);
    const [matchedItems, setMatchedItems] = useState<[number, number][]>([]);
    const [correctPosition, setCorrectPosition] = useState<number>(-1);

    useEffect(() => {
        const totalItems = question?.options?.row_count * question?.options?.column_count;
        const tempItems = Array.from({ length: totalItems }, (_, index) => String.fromCharCode(65 + index));
        const duplicatedItems = [...tempItems, ...tempItems].slice(0, totalItems);
        const shuffledItems = shuffleArray(duplicatedItems);
        setItems(shuffledItems);
        const randomPosition = Math.floor(Math.random() * shuffledItems.length);
        setCorrectPosition(randomPosition);
    }, [question?.options?.row_count, question?.options?.column_count]);

    const shuffleArray = (array: any[]) => {
        return array.sort(() => Math.random() - 0.5);
    };

    const handleItemClick = (row: number, column: number) => {
        if (showAnswer) return; // Do not allow interaction if showing answer

        // Prevent selecting or deselecting locked shaded cells
        if (shadedCells.some(([r, c]) => r === row && c === column) && lockShadedCells) {
            return;
        }

        const indexPair: [number, number] = [row, column];
        if (matchedItems.some((item) => item[0] === row && item[1] === column)) return;

        if (flippedItems.some((item) => item[0] === row && item[1] === column)) {
            setFlippedItems((prevFlippedItems) =>
                prevFlippedItems.filter((item) => item[0] !== row || item[1] !== column)
            );
        } else {
            setFlippedItems([...flippedItems, indexPair]);

            if (flippedItems.length === 1) {
                const [prevRow, prevColumn] = flippedItems[0];
                if (
                    items[prevRow * question?.options?.column_count + prevColumn] ===
                    items[row * question?.options?.column_count + column]
                ) {
                    setMatchedItems([...matchedItems, flippedItems[0], indexPair]);
                    if (
                        row === Math.floor(correctPosition / question?.options?.column_count) &&
                        column === correctPosition % question?.options?.column_count
                    ) {
                        console.log('Correct item position selected:', row, column);
                    }
                }
            }
        }

        if (action === 'lockShaded') {
            if(mode === 'create'){
                dispatch(
                    setKeyValue({
                        key: 'shading',
                        subKey: 'shaded',
                        value: [...flippedItems, indexPair],
                    })
                );
            } else if(mode === 'edit'){
                dispatch(
                    setEditKeyValue({
                        key: 'shad',
                        subKey: 'shaded',
                        value: [...flippedItems, indexPair],
                    })
                );
            }
        }

        if (action === 'correctAnswer' && !isAltAnswer) {
            if(mode === 'create'){
                dispatch(
                    setKeyValue({
                        key: 'shading',
                        subKey: 'correct_answer',
                        value: {
                            method: 'byLocation',
                            value: [...flippedItems, indexPair],
                        },
                    })
                );
            } else if(mode === 'edit'){
                dispatch(
                    setEditKeyValue({
                        key: 'shad',
                        subKey: 'correct_answer',
                        value: {
                            method: 'byLocation',
                            value: [...flippedItems, indexPair],
                        },
                    })
                );
            }
        }

        if (action === 'correctAnswer' && isAltAnswer) {
            if(mode === 'create'){
                dispatch(
                    setKeyValue({
                        key: 'shading',
                        subKey: 'alt_answer',
                        value: {
                            altIndex: altIndex, // Example: Modify this based on logic
                            method: method,
                            value: [...flippedItems, indexPair],
                        },
                    })
                );
            } else if(mode === 'edit'){
                dispatch(
                    setEditKeyValue({
                        key: 'shad',
                        subKey: 'alt_answer',
                        value: {
                            altIndex: altIndex, // Example: Modify this based on logic
                            method: method,
                            value: [...flippedItems, indexPair],
                        },
                    })
                );
            }
        }

        if (action === 'hidden') {
            if(mode === 'create'){
                dispatch(
                    setKeyValue({
                        key: 'shading',
                        subKey: 'hidden',
                        value: [...flippedItems, indexPair],
                    })
                );
            } else if(mode === 'edit'){
                dispatch(
                    setEditKeyValue({
                        key: 'shad',
                        subKey: 'hidden',
                        value: [...flippedItems, indexPair],
                    })
                );
            }
        }
    };

    return (
        <GameBoard row={question?.options?.row_count} column={question?.options?.column_count} canvasContainerStyle={question?.options?.canvas_container_style}>
            {items.map((item, index) => {
                const row = Math.floor(index / question?.options?.column_count);
                const column = index % question?.options?.column_count;
                const isShaded = Array.isArray(shadedCells) && shadedCells.some(([r, c]) => r === row && c === column);
                const isHidden = action === 'preview' && question?.options?.hidden?.some(([r, c]) => r === row && c === column);
                const validResponse = isAltAnswer
                    ? question?.alt_answer?.[altIndex]?.valid_response?.value
                    : question?.correct_answer?.valid_response?.value;

                const method = validResponse?.method;
                const countValue = parseInt(validResponse?.value, 10);
                const isCorrect = Array.isArray(correctAnswer) && correctAnswer.some(([r, c]) => r === row && c === column) || (method === 'byCount' && action == 'preview' && index < countValue && showAnswer);
                const isIncorrect = showAnswer && flippedItems.some((flipped) => flipped[0] === row && flipped[1] === column) && !isCorrect;
                const isSelected = (showAnswer || (mode == 'edit' && action == 'correctAnswer'))
                    ? isCorrect
                    : flippedItems.some((flipped) => flipped[0] === row && flipped[1] === column)

                return (
                    <GameItem
                        key={index}
                        width={question?.options?.cell_width || cellWidth}
                        height={question?.options?.cell_height || cellHeight}
                        flipped={isSelected}
                        correct={isCorrect}
                        incorrect={isIncorrect}
                        matched={matchedItems.some((i) => i[0] === row && i[1] === column)}
                        borderColor={borderColor}
                        image={image}
                        isSelected={isSelected}
                        isShaded={isShaded}
                        isHidden={isHidden}
                        lockShadedCells={lockShadedCells}
                        borderType={question?.more_options?.layout?.border_type}
                        onClick={() => handleItemClick(row, column)}
                    >
                        {isSelected && image && (
                            <img src={image} alt={question?.options?.alt}
                                 style={{zIndex: 0, width: '100%', height: '100%' }}
                                 title={question?.options?.title}
                            />
                        )}
                        {showAnswer && isCorrect && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <FaCheck style={{ color: 'green' }} />
                            </div>
                        )}
                        {showAnswer && isIncorrect && <FaTimes style={{ color: 'red' }} />}
                    </GameItem>
                );
            })}
        </GameBoard>
    );
};

export default MemoryGame;
