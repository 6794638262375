import { ChangeEventHandler, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SortableElements from '../partials/SortableElements/SortableElements';
import { sortDraggedElement } from 'pages/AuthorQuestion/ClassifyMatch/utils';
import { ShowOrderListAnswers } from './ShowOrderListAnswers';
import { TOptionItem } from 'types';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';

type OrderListBlocProps = {
  orderList: TOptionItem[];
  correctAnswers?: TOptionItem[];
  handlePointsChange?: ChangeEventHandler<HTMLInputElement>;
  editMode?: boolean;
  setOrderList?: (value: any) => void;
  handleSortUpdate?: (res: TOptionItem[]) => void;
  showAnswer?: boolean;
  score?: number;
};

export const OrderListBloc = ({
  orderList,
  correctAnswers,
  handlePointsChange,
  editMode,
  setOrderList,
  handleSortUpdate,
  showAnswer,
  score,
}: OrderListBlocProps) => {
  const { t } = useTranslation();
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleSort = () => {
    const data = orderList;

    const res = sortDraggedElement({
      data,
      dragItem: dragItem,
      dragOverItem: dragOverItem,
    });

    if (setOrderList) {
      setOrderList((prev) => res);
    }
    if (handleSortUpdate) {
      handleSortUpdate(res);
    }

    const anyItemsDropped = orderList.length>0?true:false;
    console.log(anyItemsDropped, " Dropped Items");
    setHasAnswered(anyItemsDropped);

  };
  const more_options = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["ordlis"] as any)?.more_options
  );

  const [hasAnswered, setHasAnswered] = useState<boolean>(false);
  const [showCheckAnswer, setShowCheckAnswer] = useState<boolean>(false); // State to track show/hide answer classification
  const instant_feedback = more_options?.ui_style?.instant_feedback;


  const fontSizeMapping = {
    small: "12px",
    medium: "14px",
    large: "17px",
    "x-large": "20px",
    "xx-large": "24px",
  };
  const fontSize = fontSizeMapping[more_options?.ui_style?.fontsize]  || "14px";
  console.log(fontSize);

  const handleCheckAnswerButton = (e) => {
    e.preventDefault();
    setShowCheckAnswer(!showCheckAnswer);
  };

  return (
    <div
      className="mt-4"
      style={{
        border: '1px solid #ccc',
        borderRadius: '6px',
        width: '100%',
      }}
    >
      <div className={'w-full p-2'} style={{ fontSize }}>
        {showAnswer || showCheckAnswer  ? (
          <ShowOrderListAnswers
            orderList={orderList}
            correctAnswers={correctAnswers}
            dragItem={dragItem}
            dragOverItem={dragOverItem}
            handleSort={handleSort}
          />
        ) : (
          <SortableElements
            columnTitles={orderList}
            handleColSort={handleSort}
            dragItem={dragItem}
            dragOverItem={dragOverItem}
            correctAnswers={correctAnswers}
          />
        )}
         {/* Check Answer Button */}
         {!editMode && instant_feedback &&(
        <div className="mt-6 flex justify-end">
          <button
            disabled={!hasAnswered}
            onClick={handleCheckAnswerButton}
            className={`text-right font-semibold py-2 px-4 border rounded transition duration-300 ${
              !hasAnswered
                ? "bg-[#aaaaaa] text-[#717171] cursor-not-allowed"
                : "bg-transparent hover:bg-accent text-gray-700 hover:text-white border hover:border-transparent"
            }`}
          >
            Check Answer
          </button>
        </div>
         )}
      </div>

      <div className="flex items-center justify-between mt-4 gap-2">
        {editMode && (
          <div
            className="flex items-center gap-10 p-2"
            style={{
              border: '1px solid #eee',
              borderRadius: '6px',
              width: '300px',
            }}
          >
            <span>{t('points')}</span>
            <input
              className="outline-none"
              placeholder="100"
              type="number"
              value={score ?? ''}
              onChange={handlePointsChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

OrderListBloc.defaultProps = {
  editMode: false,
};
