import { TOptionItem } from 'types';
import { DnDBetweenLists } from '../partials/DnDBetweenLists/DnDBetweenLists';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';

type SortListBlocProps = {
  sortList: TOptionItem[]; // Renamed to be specific to SortList
  correctAnswers?: TOptionItem[];
  handlePointsChange?: (e: any) => void;
  editMode?: boolean;
  setSortList?: (value: any) => void; // Renamed to be specific to SortList
  handleSortUpdate?: (res: TOptionItem[]) => void;
  showAnswer?: boolean;
  score?: number;
};

export const SortListBloc = ({
  sortList, // Use sortList instead of orderList
  correctAnswers,
  handlePointsChange,
  editMode,
  showAnswer,
  score,
  setSortList,
  handleSortUpdate,
}: SortListBlocProps) => {
  const { t } = useTranslation();
  const [localSortList, setLocalSortList] = useState<TOptionItem[]>(sortList);
  const more_options = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["sortlis"] as any)?.more_options
  );
  const [hasAnswered, setHasAnswered] = useState<boolean>(false);
  const [showCheckAnswer, setShowCheckAnswer] = useState<boolean>(false); // State to track show/hide answer classification
  const instant_feedback = more_options?.ui_style?.instant_feedback;


  useEffect(() => {
    if (setSortList) {
      setSortList(localSortList);
    }
  }, [localSortList, setSortList]);

  const handleSortUpdateInternal = (res: TOptionItem[]) => {
    setLocalSortList(res);
    if (handleSortUpdate) {
      handleSortUpdate(res);
    }
  };

  const handleCheckAnswerButton = (e) => {
    e.preventDefault();
    setShowCheckAnswer(!showCheckAnswer);
  };

  return (
    <>
      <div className={'w-full p-2'}>
        <DnDBetweenLists
          correctAnswers={correctAnswers}
          orderList={localSortList} // Use localSortList instead of orderList
          showAnswer={showAnswer}
          showCheckAnswer={showCheckAnswer}
          setHasAnswered={setHasAnswered}
        />
         {/* Check Answer Button */}
         {!editMode && instant_feedback &&(
        <div className="mt-6 flex justify-end">
          <button
            disabled={!hasAnswered}
            onClick={handleCheckAnswerButton}
            className={`text-right font-semibold py-2 px-4 border rounded transition duration-300 ${
              !hasAnswered
                ? "bg-[#aaaaaa] text-[#717171] cursor-not-allowed"
                : "bg-transparent hover:bg-accent text-gray-700 hover:text-white border hover:border-transparent"
            }`}
          >
            Check Answer
          </button>
        </div>
         )}
      </div>

      <div className="flex items-center justify-between mt-4 gap-2">
        {editMode && (
          <div
            className="flex items-center gap-10 p-2"
            style={{
              border: '1px solid #eee',
              borderRadius: '6px',
              width: '300px',
            }}
          >
            <span>{t('points')}</span>
            {score && correctAnswers ? (
              <input
                className="outline-none"
                type="number"
                value={score}
                readOnly
              />
            ) : (
              <input
                className="outline-none"
                placeholder="100"
                type="number"
                value={score}
                onChange={handlePointsChange}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

SortListBloc.defaultProps = {
  editMode: false,
};
