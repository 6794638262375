import { Fragment, useEffect, useState } from 'react';
import { QuestionContent } from './styled';
import { RightContent } from '../../../components/styled';
import ButtonLink from '../../../components/ButtonLink';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { Rnd } from 'react-rnd';
import { setClosePreview } from 'redux/slices/PreviewSlice';
import QuestiontypeModal from 'components/QuestiontypeModal';
import { getLabelByStemNumeration, getResponseFontSize } from 'utils/generalUtils';
const mathlive = require('mathlive');

export default function PreviewLabelmageMath({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) {
  const [elements, setElements] = useState([]);
  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isHovered , setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const more_options = currentQuestion?.more_options;

  const validResponse = currentQuestion?.correct_answer?.valid_response;

  useEffect(() => {
    let data =
      currentQuestion?.correct_answer &&
      currentQuestion?.correct_answer?.response_containers
        ? JSON.parse(
            JSON.stringify(currentQuestion.correct_answer?.response_containers)
          )
        : '';
    for (let i = 0; i < data.length; i++) {
      let inputString = data[i].template;
      if (inputString) {
        const parts = inputString.split('\\colorbox{#e4e4e4}{{Response}}}');
        let T = [];
        for (let i = 0; i < parts.length; i++) {
          if (parts[i] !== undefined && parts[i] !== '') {
            T.push({ type: 'txt', content: parts[i] });
            if (i != parts.length - 1)
              T.push({ type: 'Res', content: 'Response' });
          }
        }
        data[i].template = T;
      }
    }
    setElements(data);
  }, []);

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const itemMinWidth = more_options && more_options?.layout.response_min_width ?
    Number(more_options?.layout.response_min_width) : 0; 

  return (
    <QuestionContent 
      parentMode={parentMode === 'question'}
      className='overflow-x-scroll'
    >
       {
        more_options?.extras?.instructor_stimulus && !editMode &&
        <div className="p-5 mb-4 bg-blue-100 rounded-md text-black">
          {more_options?.extras?.instructor_stimulus}
        </div>
      }
      <div className="flex flex-wrap gap-5 justify-between mb-5">
        <div className="w-full lg:w-[70%]">
          <div 
            className="bg-light-accent flex p-5 gap-3 rounded-lg"
            style={more_options && more_options?.layout.fontSize ? 
              {fontSize: more_options.layout.fontSize}: 
              {fontSize: 'medium'}
            }
          >
            <p className="font-bold">Question :&nbsp;</p>
            <span
              dangerouslySetInnerHTML={{
                __html: ` ${currentQuestion?.question}`,
              }}
            />
          </div>
        </div>

        <RightContent>
          {editMode ? (
            <>
              <ButtonLink
                margin
                title={'Go to edit'}
                onClick={() => handleEditClick()}
              />
              <Button
                title={'Delete'}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />
              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <>
              <Button
                margin
                title={
                  showAnswer || localShowAnswer ? 'Hide Answer' : 'Show Answer'
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (setShowAnswers) {
                    setShowAnswers(!showAnswer);
                  } else {
                    setLocalShowAnswer(!localShowAnswer);
                  }
                }}
              />
              <Button
                title={'Back to edit'}
                onClick={() => {
                  // @ts-ignore
                  dispatch(setClosePreview());
                }}
              />
            </>
          )}
        </RightContent>
      </div>
      <div
        style={{
          position: 'relative',
          width: `${currentQuestion.image.width}px`,
          height: `${currentQuestion.image.height}px`,
        }}
        // className="image-container"
      >
        {/* Response Element */}
        {Array.isArray(elements) &&
          elements.map((element, key) => (
            <Rnd
              key={key}
              default={{
                x: element.x,
                y: element.y,
                width: element.width,
                height: element.height ? element.height : 'fit-content !important',
              }}
              minWidth={itemMinWidth}
              bounds="parent"
              disableDragging={true}
              enableResizing={false}
              style={{
                position: 'absolute',
                height: 'fit-content !important',
                padding: element?.height ? '20px' : '0px',
                border: '1px dotted #00FF00',
                cursor: 'move',
                display: 'flex',
                alignItems: 'center',
                fontSize: getResponseFontSize(more_options),
              }}
            >
              {Array.isArray(element?.template) &&
                element.template.map((elm, key) => (
                  <Fragment key={key}>
                    {elm.type === 'txt' ? (
                      //@ts-expect-error
                      <math-field 
                        readonly={true}
                        style={{ display: 'inline-flex'}}
                      >
                        {elm.content}
                        {/* @ts-expect-error */}
                      </math-field>
                    ) : (
                      !editMode ? (
                        <div
                        style={{
                          // width: '44px',
                          // height: '22px',
                          border: '1px solid #333',
                          cursor: 'move',
                          display: 'inline-flex',
                          
                        }}
                        className=' w-fit'
                      >
                        {/* @ts-expect-error */}
                        <math-field>
                        {/* @ts-expect-error */}
                      </math-field>

                      </div>
                      ) : (
                        <div 
                          style={{
                            width: '16px',
                            height: '12px',
                            border: '1px solid #333',
                            cursor: 'move',
                            display: 'inline-flex',
                          }}
                        />
                      )
                    )}
                  </Fragment>
                ))}

              <span
                style={{
                  color: '#fff',
                  position: 'absolute',
                  top: '-17px',
                  left: '-10px',
                  width: '22px',
                  height: '22px',
                  backgroundColor: '#444',
                  borderRadius: '50%',
                  fontWeight: 'bold',
                  fontSize: 'small',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {/* {element.id} */}
                {getLabelByStemNumeration(more_options, element.id != 0 ? element.id - 1 : element.id)}
              </span>
            </Rnd>
          ))}

        {/* End Response Element */}
        {currentQuestion?.image && (
          <div
            style={{
              width: currentQuestion.image.width,
              height: currentQuestion.image.height,
            }}
            className='image-container'
            // onMouseEnter={() => {setIsHovered(true)}}
            // onMouseLeave={() => {setIsHovered(false)}}
          >
            <img
              src={currentQuestion.image.src}
              alt={currentQuestion.image.alt}
              title={currentQuestion.image.title || ''}
              // className={`border border-red-800 h-full w-full`}
              className={`h-full w-full`}
            />

            {/* {isHovered && (
               <div className="tooltip" style={{
                position: 'absolute',
                bottom: '110%',
                left: '50%',
                transform: 'translateX(-50%)',
                padding: '10px',
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                color: 'white',
                borderRadius: '5px',
                whiteSpace: 'nowrap',
              }}>
                This is a tooltip text!
              </div>
            )} */}
          </div>
        )}
      </div>

      {(localShowAnswer || showAnswer) && (
        <ul className="w-full flex flex-col p-2">
          <p 
            className=" font-semibold"
            style={{fontSize: getResponseFontSize(more_options)}}  
          >
            Answers: 
          </p>
          {Array.isArray(validResponse?.value) &&
            validResponse?.value.map((vr, index) => (
              <li 
                className="mt-4" 
                style={{fontSize: getResponseFontSize(more_options)}}
                key={index}
              >
                <span className="px-3 bg-slate-500 text-white">
                  {getLabelByStemNumeration(more_options, index)}
                </span>{' '}
                {/* @ts-expect-error */}
                <math-field readonly={true}>
                  {vr?.value || ''}
                {/* @ts-expect-error */}
                </math-field>
              </li>
            ))}
        </ul>
      )}
    </QuestionContent>
  );
}
