import React, { useState, useRef, useEffect } from "react";
import JoditEditor, { IJoditEditorProps } from "jodit-react";
import { QuestionContent } from "./styled";
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from "components/styled";
import ButtonLink from "components/ButtonLink";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import { setClosePreview } from "redux/slices/PreviewSlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ClassicEditor from "ckeditor5-build-classic-extended";
import { setKeyValue } from "redux/slices/QuestionSlice";
import QuestiontypeModal from "components/QuestiontypeModal";
import CustomJoditEditor from "../../../components/CustomJoditEditor";

interface PreviewWrittenRecordedMathEssayPTProps {
  currentQuestion: {
    id: number;
    question: string;
    more_options: {
      metadata: {
        distractor_rationale: string;
        rubric_reference: string;
        sample_answer: string;
        acknowledgements: string;
        stimulus_review: string;
        instructor_stimulus: string;
      };
      validation: {
        max_score: string;
        min_score_if_attempted: number;
      };
      ui_style: {
        fontsize: string;
        min_height: string;
        max_height: string;

        disable_auto_link: string;
        placeholder: string;
        submit_over_limit: boolean;
        spellcheck: boolean;
        is_math?: boolean;
      };
    };
    options: {
      show_copy: boolean;
      show_cut: boolean;
      show_paste: boolean;
      max_length: number;
      show_word_limit: string;
      text_blocks: [];
      content: string;
      formatting_options: [];
      show_word_count: boolean;
    };

    content: string;
    formatting_options: string[];
    type: string;
  };
  showAnswer?: any;
  setShowAnswers?: any;
  parentMode?: string;
  editMode?: boolean;
}

const PreviewWrittenRecordedMathEssayPT: React.FC<
  PreviewWrittenRecordedMathEssayPTProps
> = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = "question",
  editMode = false,
}) => {
  const [content, setContent] = useState<string>(
    currentQuestion?.content || ""
  );
  const [isOverLimit, setIsOverLimit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const maxWords = currentQuestion?.options?.max_length || 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const placeholder = currentQuestion.more_options?.ui_style?.placeholder;
  const minHeight = currentQuestion.more_options?.ui_style?.min_height;
  const maxHeight = currentQuestion.more_options?.ui_style?.max_height;
  const instructorStimulus =
    currentQuestion.more_options?.metadata?.instructor_stimulus;
  const fontSize = currentQuestion.more_options.ui_style.fontsize;
  const fontSizeMapping = {
    small: "12px",
    medium: "14px",
    large: "17px",
    "x-large": "20px",
    "xx-large": "24px",
  };
  const mappedFontSize = fontSizeMapping[fontSize] || "14px";

  const handleEditorBlur = (newContent: string) => {
    const text = newContent.trim();
    const words = text.split(/\s+/).filter((word) => word !== "");

    if (words.length > maxWords) {
      setIsOverLimit(true);
    } else {
      setIsOverLimit(false);
    }
    setContent(newContent);
  };

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const clipboardButtons = [];
  if (currentQuestion.options.show_cut) clipboardButtons.push("cut");
  if (currentQuestion.options.show_copy) clipboardButtons.push("copy");
  if (currentQuestion.options.show_paste) clipboardButtons.push("paste");

  const config = {
    minHeight: minHeight || 200,
    maxHeight,
    readonly: editMode,
    buttons: clipboardButtons || [],
    placeholder: placeholder || "",
  };

  const wordCount =
    content.trim() === "<p><br></p>"
      ? 0
      : content
          .trim()
          .split(/\s+/)
          .filter((word) => word !== "").length;

  return (
    <QuestionContent
      parentMode={parentMode === "question"}
      style={{ fontSize: mappedFontSize }}
    >
      <FullWidthContainer>
        <LeftContent>
          {instructorStimulus !== "" && !editMode && (
            <BlockTitle>{instructorStimulus}</BlockTitle>
          )}
          <BlockTitle
            dangerouslySetInnerHTML={{
              __html: `Question: ${currentQuestion?.question}`,
            }}
          />
        </LeftContent>
        <RightContent>
          {editMode ? (
            <>
              <ButtonLink
                margin
                title={"Go to edit"}
                onClick={() => handleEditClick()}
              />
              <ButtonLink
                margin
                title={"Delete"}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />

              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id as any}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => {
                  dispatch(setClosePreview());
                }}
                style={{
                  width: "150px",
                  height: "40px",
                  fontWeight: 600,
                  color: "#FFF",
                  borderRadius: "8px",
                  backgroundColor: "#FA9B31",
                }}
              >
                Back to edit
              </button>
            </>
          )}
        </RightContent>
      </FullWidthContainer>
      <div>
        {/* <div className={isOverLimit ? "editor-over-limit" : "editor"}>
          <CKEditor
            editor={ClassicEditor}
            data={content}
            config={config}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleEditorBlur(data);
            }}
          />
        </div> */}
        <CustomJoditEditor
            label=""
            value={content}
            onBlur={(newContent) => handleEditorBlur(newContent)}
            allconfig={false}
            userconfig={config}
            spellCheck={currentQuestion?.more_options?.ui_style?.spellcheck}
            minHeight={currentQuestion?.more_options?.ui_style?.min_height}
            maxHeight={currentQuestion?.more_options?.ui_style?.max_height}
        />
        <div
          className="flex gap-1 mt-2"
          style={isOverLimit ? { color: "red" } : {}}
        >
          {currentQuestion?.options?.show_word_limit === "alwaysVisible" || currentQuestion?.options?.show_word_limit === "" && (
              <p>{wordCount} / {maxWords} Word Limit</p>
          )}
          { (currentQuestion?.options?.show_word_limit === "onLimit" && (wordCount < maxWords || wordCount == 0)) && (
              <p>{wordCount} Word(s)</p>
          )}
          {(currentQuestion?.options?.show_word_limit === "off" && (wordCount > maxWords || wordCount == 0)) && (
              <p>{wordCount} Word(s)</p>
          )}
          {(currentQuestion?.options?.show_word_limit === "onLimit" && wordCount > maxWords && wordCount !== 0) && (
              <p>{wordCount} / {maxWords} Word Limit</p>
          )}
        </div>
      </div>
    </QuestionContent>
  );
};

export default PreviewWrittenRecordedMathEssayPT;
