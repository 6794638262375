import DOMPurify from 'dompurify';
import { useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from 'types';

type DropZoneProps = {
  id: number;
  onDrop?: (id: number, word: string) => void;
  filledWord?: string;
};

type dragItem = {
  label: string;
};

const DropZone = ({ id, onDrop, filledWord }: DropZoneProps) => {
  const textRef = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.MATCH,
    drop: (item: dragItem) => {
      onDrop(id, item?.label);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  // parse an html string
  useEffect(() => {
    textRef.current.innerHTML = DOMPurify.sanitize(filledWord);
  }, [textRef, filledWord]);

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        background: `${filledWord !== 'Response' ? '#F0F8ED' : '#fff'}`,
      }}
      className="p-2 border-solid border border-grey-500 rounded-md my-4 min-w-[300px] min-h-[48px]"
    >
      <div
        style={{
          background: `${filledWord !== 'Response' ? '#F0F8ED' : '#fff'}`,
        }}
      >
        {<span ref={textRef} />}
      </div>

      {isOver && (
        <div
          className="border-solid border border-grey-500 rounded-md"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'rgb(255, 102, 1)',
          }}
        />
      )}
    </div>
  );
};

export default DropZone;
