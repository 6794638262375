import React, { useEffect, useRef, useState } from 'react';
import Geogebra from 'react-geogebra'

const CanvasGeogebra = ({drawingTools, layout} : {drawingTools: string[], layout: any}) => {
    // const geoRef = useRef(null);
    // //@ts-ignore
    // const app = window?.app1 ? window?.app1 : null;
    // const [loaded, setLoaded] = useState(false)

    // useEffect(() => {
    //     if(loaded) {
    //         const el = document.getElementById('app1');
    //         console.log("el =", el)
    //     }
    // }, [loaded])

    // const setGeoBackgroundImage = () => {
    //   if (app) {
    //     // Insert image at coordinates (0, 0) with dimensions
    //     // app.evalCommand(
    //     //     'image1 = Image("https://media.istockphoto.com/id/1386740242/vector/vector-bubbles-with-question-mark-question-icons-isolated-on-white.jpg?s=612x612&w=0&k=20&c=evjrckVKb_RVRcN5qV1Tz1pkSu3FvHKCGtynu8btxhA=", 0, 0, 4, 4)'
    //     // );
        
    //     // // You can also set the size and position of the image like this:
    //     // app.evalCommand('SetCoords(image1, 1, 1)');
    //     // app.evalCommand('SetFixed(image1, true, false)');
    //   }
    // };
  
    // useEffect(() => {
    //   // Add a delay or ensure the app is loaded before calling setBackgroundImage
    //   const timer = setTimeout(() => {
    //     setGeoBackgroundImage();
    //   }, 500); // Delay to ensure GeoGebra is fully initialized
  
    //   return () => clearTimeout(timer);
    // }, []);


    const getCustomeToolBar = () => {
        let mappedTools = '0 1 39 40 ';
        // image: 26
        const mappedToolsObj = {
            Scribble: '61 62 73 ', 
            Line: '15 45 ', 
            Compass: '10 20 24 34 53 55 ', 
            Eraser: '6 ', 
            Text: '17 2040 ',
        }
        Object.keys(mappedToolsObj).forEach((key: string) => {
            if(drawingTools.includes(key)){
                mappedTools += mappedToolsObj[`${key}`];
            }
        })
        return mappedTools;
    }

    const width = layout && !isNaN(Number(layout?.canavas_width)) ? Number(layout?.canavas_width) : 800;
    const height = layout && !isNaN(Number(layout?.canavas_height)) ? Number(layout?.canavas_height) : 600;
    const showGrid = layout && layout?.show_grid ? Boolean(layout?.show_grid) : false;

    return(
        <Geogebra
            width={width}
            height={height}
            showMenuBar={true}
            showToolBar={true}
            showAlgebraInput={false}
            id='app1'
            appName='Obeassess'
            appletOnLoad={() => {
                // setLoaded(true)
            }}
            LoadComponent={() => <h1>Loading</h1>}
            reloadOnPropChange={true}
            // customToolBar='1 17 6 2 10 15 20 24 34 39 40 53 55 61 62 73 2040 26'
            customToolBar={getCustomeToolBar()}
            showStartTooltip={false}
            showResetIcon = {drawingTools.includes('Clear')}
            // showLogging = {true}
            showSuggestionButtons = {false}
            showAnimationButton = {false}
            showToolBarHelp={false}
            showZoomButtons = {false}
            errorDialogsActive={false}
            // useBrowserForJS = {true}
            enableFileFeatures={false}
            // @ts-ignore
            enableUndoRedo = {drawingTools.includes('Undo/Redo')}
            perspective={showGrid ? 'G' : '2'}
            // perspective={drawingTools.includes('Grid') ? 'G' : '2'}
            transparentGraphics = {true}
        />
    )
}

export default CanvasGeogebra;
