import { DraggableLocation } from 'react-beautiful-dnd';
import { TOptionItem } from 'types';

const move = (
  source: TOptionItem[],
  destination: TOptionItem[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
  isDuplicateResponse: boolean
) => {
  const sourceClone = [...source];
  const destClone = [...destination];

  let itemToMove: TOptionItem;
  
  if (isDuplicateResponse) {
    itemToMove = { ...sourceClone[droppableSource.index] };
  } else {
    [itemToMove] = sourceClone.splice(droppableSource.index, 1);
  }

  destClone.splice(droppableDestination.index, 0, itemToMove);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const reorder = (list: TOptionItem[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    label: `item ${k + offset}`,
  }));

export { move, reorder, getItems };
