import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import MemoryGame from "../../AuthorQuestion/HighlightDrawing/MemoryGame";

const PreviewShading = ({
                            currentQuestion,
                            showAnswer = undefined,
                            setShowAnswers = undefined,
                            parentMode = 'assessment',
                            editMode = false,
                        }) => {
    const [localShowAnswer, setLocalShowAnswer] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleBackToEditClick = () => {
        dispatch(setClosePreview());
    };

    const handleGoToEditClick = (e) => {
        e.preventDefault();
        dispatch(
            setTypeQuestionOpenInEditMode({
                type: currentQuestion?.type,
                id: currentQuestion?.id,
            })
        );
        navigate(`/edit-subquestion/${currentQuestion.id}`);
    };

    // Extract data from currentQuestion with default values
    const {
        options: {
            row_count = "1",
            column_count = "1",
            cell_width = "50",
            cell_height = "50",
            shaded = [],
            lock_shaded_cell = false,
            image: { source = '' } = {},
            canvas_container_style = {},
        } = {},
        correct_answer: {
            valid_response: {
                value: { value: correctAnswer = [] } = {},
            } = {},
        } = {} ,
    } = currentQuestion || {};

    return (
        <PreviewWrapper
            currentQuestion={currentQuestion}
            showAnswer={showAnswer ?? localShowAnswer}
            setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
            parentMode={parentMode}
            editMode={editMode}
            handleGoToEdit={(e) => handleGoToEditClick(e)}
            handleBackToEdit={handleBackToEditClick}
        >

            {/* Render the MemoryGame Component in Preview Mode */}
            <MemoryGame
                currentQuestion={currentQuestion}
                action={'preview'}
                image={source}
                showAnswer={showAnswer ?? localShowAnswer}
                correctAnswer={correctAnswer}
                shadedCells={shaded} // Pass the shaded cells
                lockShadedCells={lock_shaded_cell} // Pass lock state
            />
        </PreviewWrapper>
    );
};

export default PreviewShading;
