import { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import styled from 'styled-components';
import VerticalInput from "components/ComposeQuestion/components/verticalRadioInput";
import TabInput from "components/ComposeQuestion/components/tabInput";
import RadioInput from "components/ComposeQuestion/components/radioInput";


const ChildContent = styled.div`
  padding: 20px;
  margin: 25px 0;
  background: #fff;
`;
 
const OptionsForMcq = ({
    data,
    showAnswer,
    multiResponse,
  }) => {
    const textRef = useRef<Array<HTMLDivElement | null>>([]);
    const more_options = data?.more_options ? data?.more_options : {layout:{}, scoring:{}, extras: {}};
    const questionType = data?.type || "";
    const [inputType, setInputType] = useState(questionType == 'mcbl' ? 'block' : 'standard');
    const items = data?.options || [];
    const correctAnswerTab = data?.correct_answer['valid_response'];
    const [localSelectedItems, setLocalSelectedItems] = useState<string[]>([])

    // console.log("multi response  =", multiResponse)
    // console.log("data  =", data)
  
    useEffect(() => {
      items?.map((item, i) => {
        // parse html string
        if (textRef.current[i]) {
          textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
         // window.MathJax.typesetPromise([textRef.current[i]]); 
         if (window.MathJax && window.MathJax.typesetPromise) {
          window.MathJax.typesetPromise([textRef.current[i]])
            .then(() => {
              console.log('MathJax typesetting completed');
            })
            .catch((err: Error) => {
              console.error('MathJax typesetting failed:', err);
            });
        } 
        }
        return textRef.current[i].innerHTML;
      });
    }, [items, inputType]);
  
    useEffect(() => {
      if(more_options && more_options?.layout && more_options?.layout?.type) {
        if(more_options.layout.type != inputType)setInputType(more_options.layout.type)
      }
    }, [more_options])
  
    const RenderChild = (index: number) =>{
     return (
      <h2
        className="answer-placeholder"
        data-text="question answer here..."
      >
          {/* {label} */}
          <div ref={(el) => (textRef.current[index] = el)} />
      </h2>
      );
    }
  
    const colNumber = more_options?.layout?.columns ? more_options?.layout?.columns : '1';
    //const orientation = more_options?.layout?.orientation ? more_options?.layout?.orientation == 'horizontal' ? 'row' : 'col' : 'row';
    const gridTemplateColumns = `repeat(${colNumber}, auto)`;
    
    const getLabel = (index: number) => {
      let label = "";
      if(more_options?.layout?.choice_label) {
        switch (more_options?.layout?.choice_label) {
          case 'numerical':
            label = (index + 1).toString();
            break;
          case 'lower-alpha' :
            label = String.fromCharCode(97 + index);
            break;
          case 'upper-alpha' :
            label = String.fromCharCode(97 + index).toUpperCase();
            break;
          default:
            break;
        }
      } 
      return label;
    } 

    const isActive = (item: any) => {
      if(showAnswer) {
        return correctAnswerTab && correctAnswerTab?.value.findIndex((v) => v?.id === item.id) > -1;
      }else {
        return localSelectedItems.includes(item?.id)
      }
    }
  
    return (
      <ChildContent
        style={{
          fontSize: more_options?.layout.fontSize ? more_options?.layout.fontSize : '14px',
          gridTemplateColumns
        }}
        className={`grid gap-2`}
      >
        {
          more_options?.extras?.instructor_stimulus && 
          <div className="p-5 bg-blue-200 rounded-md text-black">
            {more_options?.extras?.instructor_stimulus}
          </div>
        }

        {Array.isArray(items) &&
          items?.map((item, index) => {
              if(inputType == "horizontal-input-button"){
                return (
                  <VerticalInput 
                    key={index}
                    isActive={isActive(item)}
                    isCheckBox={multiResponse}
                    handleItemClicked={() => {
                      if(multiResponse) {
                        if(localSelectedItems.includes(item?.id)) {
                          setLocalSelectedItems(localSelectedItems.filter(id => id != item?.id))
                        }else{
                          setLocalSelectedItems([...localSelectedItems, item?.id])
                        }
                      }else {
                        setLocalSelectedItems([item?.id])
                      }
                    }}
                    item={item}
                    otherStyle={isActive(item) && showAnswer ? {backgroundColor: '#7ac491'} : {}}
                  >
                    {RenderChild(index)}
                  </VerticalInput>
              );
            }
            else if( inputType === "block"){
              return (
                <TabInput
                  isActive={isActive(item)}
                  handleItemClicked={() => {
                    if(multiResponse) {
                      if(localSelectedItems.includes(item?.id)) {
                        setLocalSelectedItems(localSelectedItems.filter(id => id != item?.id))
                      }else{
                        setLocalSelectedItems([...localSelectedItems, item?.id])
                      }
                    }else {
                      setLocalSelectedItems([item?.id])
                    }
                  }}
                  item={item}
                  label={getLabel(index)}
                  key={index}
                  otherStyle={isActive(item) && showAnswer ? {backgroundColor: '#7ac491'} :
                    isActive(item) ? {backgroundColor: '#de202a'} :
                  {}}
                >
                  {RenderChild(index)}
                </TabInput>
              )
            }else {
              return (
                <RadioInput 
                  isActive={isActive(item)}
                  isCheckBox={!!data.settings.multiple_response}
                  handleItemClicked={() => {
                    if(multiResponse) {
                      if(localSelectedItems.includes(item?.id)) {
                        setLocalSelectedItems(localSelectedItems.filter(id => id != item?.id))
                      }else{
                        setLocalSelectedItems([...localSelectedItems, item?.id])
                      }
                    }else {
                      setLocalSelectedItems([item?.id])
                    }
                  }}
                  item={item}
                  key={index}
                  otherStyle={isActive(item) && showAnswer ? {backgroundColor: '#7ac491'} : {}}
                >
                  {RenderChild(index)}
                </RadioInput>
            );
            }
          })}
      </ChildContent>
    );
}

export default OptionsForMcq;